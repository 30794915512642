import clsx from "clsx";

export const KioskLayout = ({ className, children }) => {
  return (
    <div
      className={clsx(
        "relative w-full min-h-[120rem] flex flex-col p-24 bg-[#00549a] overflow-hidden",
        className
      )}
    >
      {/* <div className="absolute left-0 top-0">
        <img
          className="max-w-none w-[2016px] h-auto"
          src={require("assets/images/indian-mandala.png")}
          alt="Indian Mandala"
        />
      </div> */}

      <div className={clsx("relative w-full")}>
        <div className="mb-12">
          <img src={require("assets/images/logo.png")} alt="Logo" />
        </div>

        <div className="text-BellSlim-Heavy text-[#fff] uppercase leading-none text-[100px]">
          DIWALI FESTIVAL
          <br />
          OF LIGHTS
        </div>
      </div>

      <div className="relative grow">{children}</div>
    </div>
  );
};
