import { MobileLayout } from "components";
import { useEffect, useState } from "react";
import { STATUS, moveNextStep, subscribeCurrentSessionId } from "utils";

export const MobileKioskAdmin = () => {
  const [sessionId, setSessionId] = useState(null);

  useEffect(() => {
    const passCode = new URLSearchParams(window.location.search).get(
      "passcode"
    );

    if (!passCode || process.env.REACT_APP_ADMIN_PASSCODE !== passCode) {
      window.location.href = "/";
    }

    const unsubscribe = subscribeCurrentSessionId(setSessionId);

    return () => {
      if (typeof unsubscribe === "function") unsubscribe();
    };
  }, []);

  const handleResetKiosk = async () => {
    await moveNextStep(sessionId, STATUS.DISCONNECTED);
  };

  return (
    <MobileLayout>
      <div className="grow flex items-center justify-center mt-12">
        {!!sessionId && (
          <button
            className="border-2 border-[#fff] text-white text-BellSlim-Heavy px-20 py-2 uppercase rounded-xl"
            onClick={handleResetKiosk}
          >
            Reset Kiosk
          </button>
        )}
      </div>
    </MobileLayout>
  );
};
