import clsx from "clsx";

export const MobileLayout = ({
  className,
  children,
  onClick: handleClick = () => null,
}) => {
  return (
    <div
      className={clsx(
        "relative w-screen h-screen flex flex-col p-4 bg-[#00549a] overflow-hidden",
        className
      )}
      onClick={handleClick}
    >
      {/* <div className="absolute left-0 top-0">
        <img
          className="max-w-none w-[700px] h-auto"
          src={require("assets/images/indian-mandala.png")}
          alt="Indian Mandala"
        />
      </div> */}

      <div className="relative w-full">
        <div className="h-16 mt-16 mb-4">
          <img
            className="h-full w-auto"
            src={require("assets/images/logo.png")}
            alt="Logo"
          />
        </div>

        <div className="text-BellSlim-Heavy text-[#fff] text-[40px] uppercase leading-none">
          DIWALI FESTIVAL
          <br />
          OF LIGHTS
        </div>
      </div>

      <div className="relative grow">{children}</div>
    </div>
  );
};
