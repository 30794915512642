import "assets/css/app.css";
import { useMobileDevice } from "hooks";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { CloudRoutes, KioskRoutes } from "routes";
import { MobileKioskAdmin } from "screens";

const App = () => {
  const isMobile = useMobileDevice();

  if (isMobile === null) return null;

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/admin" element={<MobileKioskAdmin />} />
        <Route path="/kiosk-game" element={<KioskRoutes />} />
        <Route path="/cloud-game" element={<CloudRoutes />} />
        <Route
          path="*"
          element={
            <div className="text-[100px] text-BellSlim-Heavy text-center py-24">
              Not Found
            </div>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
